import {PageLayout} from "@components";
import {PartnersV2} from "../../landingpagev2/_components/_Partners/_Partners";
import {JoinInstitutionsV2} from "../../landingpagev2/_components/_JoinInstitutionsV2/_JoinInstitutionsV2";
import React from "react";
import FeatureCard, {FeatureCardLayout, FeatureCardProps} from "@components/FeatureCard/FeatureCard";
import {StaticImage} from "gatsby-plugin-image";
import * as styles from "../../products/orders/orders.module.scss";
import {Demo} from ".././biopharmaceutical";
import Margins from "@components/Margins/Margins";

export default function Manufacturing () {
    const demoProps = {
        title: "Streamline biomanufacturing operations using Genemod’s centralized lab automation software",
        subtitle: "Genemod offers a contemporary lab management platform to enhance biomanufacturing research.",
        srcImg: "biomanufacturing-operation.png",
        altImg: "biomanufacturing operation",
        disableBackgroundShapes: true,
    }

    const cards: FeatureCardProps[] = [
        {
            title: "Consolidate all research and development data on a unified platform",
            list: [
                "Accelerate every phase in biomanufacturing, ensuring rapid production cycles and smarter decision-making.",
                "Integrate Genemod to revolutionize biomanufacturing procedures, paving the way for cutting-edge production techniques.",
                "Manage resources effectively, ensuring economic efficiency and enhancing biomanufacturing quality.",
            ],
            image: (
                <StaticImage
                    src={"../../../pages/products/lab-protocol/_assets/protocols_image_2.png"}

                    alt="Easily Manage and Submit Orders"
                />
            ),
        },
        {
            title: "Foster innovation in biomanufacturing with our comprehensive lab solutions",
            list: [
                "Laboratory information management system",
                "Electronic lab notebook",
                "AI platform",
            ],
            sublist: [
                "Genemod's LIMS provides an integrated solution for handling all lab data, encompassing research information, sample monitoring, testing outcomes, quality assurance, and data interpretation.",
                "Efficiently store, organize, and document research data and reports. Facilitate team collaboration on a centralized platform, minimizing time spent on redundant processes.",
                "Genemod integrates ChatGPT in our electronic lab notebook, enabling report revisions at a click and providing instant information through our Genemod AI chat bot.",
            ],
            image: (
                <StaticImage
                    src={"../../../assets/products/single-source-of-truth.png"}
                    alt="Single Source of truth "
                />
            ),
            orientation: "right",
        },
    ];

    const footerContent = {
        headerText: "Powered by Genemod",
        subheaderText: "Our Enterprise plans help companies ensure data integrity and improve collaboration institution-wide. Each subscription is acustom-built, expert-driven foundation of your lab automation program.",
    }

    return (
        <PageLayout
            seoOptions={{ type: "PREDEFINED", pageName: "manufacturing" }}
        >
            <Margins>
            <Demo {...demoProps}/>
            <FeatureCardLayout className={styles.cards}>
                {cards.map((card, index) => {
                    return <FeatureCard {...card} key={index} />;
                })}
            </FeatureCardLayout>
            <PartnersV2 includeHighPerformer />
            <JoinInstitutionsV2 {...footerContent} />
            </Margins>
        </PageLayout>
    );
}